<template>
  <el-main>
    <div class="searchdiv">
      <div class="searchform">
        <el-input v-model="searchinfo.remarks"
                  placeholder="请输入说明关键字"
                  label="说明关键字"
                  clearable></el-input>
        <el-button type="primary" @click="dosearch()" icon="el-icon-search" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.search') }}
        </el-button>
      </div>
    </div>
    <div class="pagecontent">
      <el-table ref="multipleTable"
                :data="list"
                tooltip-effect="dark"
                style="width: 100%">
        <el-table-column
            label="ID"
            type="index"
            width="50">
        </el-table-column>
        <el-table-column
            prop="name"
            label="分店名称">
        </el-table-column>
        <el-table-column
            label="账变前金额"
            prop="front_amount" width="120">
        </el-table-column>
        <el-table-column
            label="账变金额"
            prop="amount" width="120">
        </el-table-column>
        <el-table-column
            label="账变后金额"
            prop="last_amount" width="120">
        </el-table-column>
        <el-table-column
            label="账变说明"
            prop="remarks">
        </el-table-column>
        <el-table-column
            label="是否支付"
            prop="is_pay" width="120">
          <template slot-scope="scope">
            <el-link
                :type="scope.row.is_pay?'success':'danger'"
                >{{ scope.row.is_pay ? '已支付' : '待支付' }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
            :label="$t('grade.created_at')"
            width="160" prop="created_at">
        </el-table-column>
      </el-table>
      <el-pagination
          background
          @size-change="SizeChange"
          @current-change="getlist"
          :current-page="searchinfo.page"
          :page-sizes="PAGE.pageSizes"
          :page-size="searchinfo.pagesize"
          :layout="PAGE.layout"
          :total="totalRows">
      </el-pagination>
    </div>
  </el-main>
</template>

<script>
export default {
  name: 'user',
  data() {
    return {
      searchinfo: {
        page: 1,
        pagesize: this.PAGE.pageSize,
        depart_id:'',
        remarks: '',
      },
      list: [],
      shoplist:[],
      totalRows: 0,
      defaultuserinfo: {
        id: 0,
        depart_id: '',
        remarks: 0,
        is_pay: '',
        amount:'',
      },
      gradeformdata: {},
      rules: {},
      isshowdialog: false,
      isbusy: false,
    }
  },
  methods: {
    SizeChange(val) {
      this.searchinfo.pagesize = val;
      this.getlist(1);
    },
    async dosearch() {
      this.getlist(1);
    },
    async getlist(page) {
      this.searchinfo.page = page || this.searchinfo.page;
      this.isbusy = true;
      let res = await this.httpget('/system/fundlogs/index', this.searchinfo);
      if (res.code === 0) {
        this.list = [];
        res.data.data.forEach((data) => {
          this.list.push(data);
        })
        this.totalRows = res.data.total;
        this.isbusy = false;
      } else {
        this.isbusy = false;
      }

    },
    dosubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.suresubmit();
        } else {
          return false;
        }
      });
    },
    async suresubmit() {
      this.isbusy = true;
      let res = await this.httppost('/system/fundlogs/save', this.gradeformdata);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        this.getlist(1);
        this.gradeformdata = {};
        this.isshowdialog = false;
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    }
  },
  mounted() {
    this.getlist(1);
  },
  computed: {
    langlist: {
      get: function () {
        return this.$store.getters.getLanglist;
      }
    },
  },
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

.avatar {
  width: 100px;
  height: 100px;
  display: block;
}

.edui-popup-content.edui-default{ height: auto !important; }
.edui-combox-body.edui-default{
  height:25px;

}
.edui-default .edui-toolbar .edui-combox-body .edui-button-body{
  line-height: 25px;
}
.edui-default .edui-toolbar .edui-combox-body .edui-button-body{
  line-height: 25px !important;
}
.edui-default .edui-toolbar .edui-splitborder{
  height:25px !important;
}
.edui-default .edui-toolbar .edui-combox-body .edui-arrow{
  height:25px !important;
  width:13px !important;
}
#edui_fixedlayer{
  z-index:100000 !important;
}
</style>
