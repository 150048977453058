<template>
  <el-main>
    <div class="searchdiv">
      <div class="searchform">
        <el-input v-model="searchinfo.title"
                  placeholder="请输入技师姓名"
                  label="技师"
                  clearable></el-input>
        <el-button type="primary" @click="dosearch()" icon="el-icon-search" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.search') }}
        </el-button>
      </div>
      <div>
        <el-button type="primary" @click="addgrade('userform')" icon="el-icon-plus">添加技师</el-button>
      </div>
    </div>
    <div class="pagecontent">
      <el-table :data="list"
                tooltip-effect="dark"
                style="width: 100%">
        <el-table-column
            label="ID"
            prop="id" width="60">
        </el-table-column>
        <el-table-column
            prop="name"
            label="技师姓名" min-width="200px">
          <template slot-scope="scope">
            <el-popover
                placement="right"
                trigger="hover">
              <img :src="scope.row.pic" style="width:180px;"/>
              <img slot="reference" :src="scope.row.pic" :alt="scope.row.name"
                   style="height:40px;">
            </el-popover>
            <div style="display: inline-block; height: 40px; line-height: 40px; vertical-align:top; margin-left:10px;">{{ scope.row.name }}</div>
          </template>
        </el-table-column>
        <el-table-column
            label="性别"
            prop="gender"
            width="80">
        </el-table-column>
        <el-table-column
                label="抽成比例"
                prop="percent"
                width="80">
        </el-table-column>
        <el-table-column
            label="所属分店"
            prop="dname" width="160">
        </el-table-column>
        <el-table-column
            label="联系电话"
            prop="mobile" width="120">
        </el-table-column>
        <el-table-column
            label="预约费"
            prop="deposit" width="100">
          <template slot-scope="scope">
            {{ scope.row.deposit }} 元
          </template>
        </el-table-column>
        <el-table-column
            label="技师状态"
            prop="status" width="100">
          <template slot-scope="scope">
            <el-link :type="scope.row.status?'success':'danger'"
                     @click="setenable(scope.row)">{{ scope.row.status ? $t('global.enable') : $t('global.disable') }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
            label="添加时间"
            width="160" prop="created_at">
        </el-table-column>
        <el-table-column :label="$t('global.action')" width="165" fixed="right">
          <template slot-scope="scope">
            <el-link class="optlink" @click="editgrade(scope.row)">{{ $t('global.edit') }}</el-link>
            <el-link class="optlink" @click="deluser(scope.row)">{{ $t('global.delete') }}</el-link>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          background
          @size-change="SizeChange"
          @current-change="getlist"
          :current-page="searchinfo.page"
          :page-sizes="PAGE.pageSizes"
          :page-size="searchinfo.pagesize"
          :layout="PAGE.layout"
          :total="totalRows">
      </el-pagination>
    </div>
    <el-dialog :title="gradeformdata.id?'编辑技师':'添加技师'"
               :visible.sync="isshowdialog"
               :close-on-click-modal="false" custom-class="w75">
      <el-form :model="gradeformdata" :rules="rules" ref="userform" :show-message="false">
        <el-tabs type="card">
          <el-tab-pane label="基础信息">
            <el-form-item label="技师照片" label-width="100px" prop="pic" required>
              <el-upload
                  class="avatar-uploader"
                  :action="uploadurl"
                  :headers="uploadheader"
                  :show-file-list="false"
                  :on-success="uploadsuccess"
                  :before-upload="beforeupload">
                <img v-if="gradeformdata.pic" :src="gradeformdata.pic" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="技师姓名" label-width="100px" prop="name" required>
              <el-input type="text" v-model="gradeformdata.name" autocomplete="off" class="iw-xlg"></el-input>
            </el-form-item>
            <el-form-item label="性别" label-width="100px" prop="gender">
              <el-radio-group v-model="gradeformdata.gender">
                <el-radio label="男"></el-radio>
                <el-radio label="女"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="抽成比例" label-width="100px" prop="title" required>
              <el-input type="text" v-model="gradeformdata.percent" autocomplete="off" class="iw-xlg"></el-input>
            </el-form-item>
            <el-form-item label="技师职称" label-width="100px" prop="title" required>
              <el-input type="text" v-model="gradeformdata.title" autocomplete="off" class="iw-xlg"></el-input>
            </el-form-item>
            <el-form-item label="联系手机" label-width="100px" prop="mobile" required>
              <el-input type="number" v-model="gradeformdata.mobile" autocomplete="off" class="iw-xlg"></el-input>
            </el-form-item>
            <el-form-item label="预约费用" label-width="100px" prop="deposit" required>
              <el-input type="number" v-model="gradeformdata.deposit" autocomplete="off" class="iw-xlg"></el-input>
            </el-form-item>
            <el-form-item label="显示状态" label-width="100px" required>
              <el-switch v-model="gradeformdata.status" :active-value="1" :inactive-value="0" :active-text="$t('global.yes')" :inactive-text="$t('global.no')"></el-switch>
            </el-form-item>
            <el-form-item label="技师简介" label-width="100px" prop="description">
              <el-input type="textarea" v-model="gradeformdata.description"></el-input>
            </el-form-item>
            <el-form-item label="擅长领域" label-width="100px" prop="description">
              <el-input type="textarea" v-model="gradeformdata.good_at"></el-input>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="详细介绍">
            <el-form-item prop="info">
              <vue-ueditor-wrap v-model="gradeformdata.info" :config="myConfig"></vue-ueditor-wrap>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="排班列表">
            <div class="week">
              <ul>
                <template v-for="week in weekarr">
                <li>
                  <span>星期{{ week.name }}</span>
                  <span :class="week.is_rest ?'rest':''" @click="setRest(week)">休</span>
                </li>
                </template>
              </ul>
            </div>
          </el-tab-pane>
          <el-tab-pane label="项目价格">
            <template>
              <el-table :data="projectarr"
                  ref="multipleTable"
                  @selection-change="handleSelectionChange"
                  style="width: 100%">
                <el-table-column
                    type="selection"
                    width="55">
                </el-table-column>
                <el-table-column
                    prop="title"
                    label="项目名称"
                    width="160">
                </el-table-column>
                <el-table-column
                    prop="info"
                    label="项目介绍">
                </el-table-column>
                <el-table-column
                    prop="price"
                    width="120"
                    label="技师收费">
                  <template slot-scope="scope">
                    <el-input type="number" v-model="scope.row.price"></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                        width="350"
                        label="等级收费">
                  <template slot-scope="scope">
                    <div v-for="(item,index) in scope.row.tprice" :key="index">
                      <span>{{item.name}}：</span>
                      <el-input type="number" :value="item.tprice" @input="changethisinput($event,scope.row,item)" style="display:inline-block; width:120px; margin-left:10px;"></el-input>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-tab-pane>
        </el-tabs>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isshowdialog = false" :disabled="isbusy" :loading="isbusy">{{ $t('global.cancel') }}
        </el-button>
        <el-button type="primary" @click="dosubmit('userform')" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.submit') }}
        </el-button>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
import VueUeditorWrap from 'vue-ueditor-wrap'

export default {
  name: 'masseur',
  components: {
    VueUeditorWrap
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'));
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error('请输入正确的手机号'));
        }
      }
    };
    return {
      searchinfo: {
        page: 1,
        pagesize: this.PAGE.pageSize,
        title: '',
      },
      depart:[],
      list: [],
      totalRows: 0,
      defaultuserinfo: {
        id: 0,
        name: '',
        title: '',
        pic: '',
        mobile: '',
        gender: '女',
        fee: '',
        deposit: 0,
        description:'',
        good_at:'',
        info:'',
        remarks:'',
        chooseweek:[],
        chooseproject:[],
        status: 1,
      },
      editprojectcc:[],
      grade:[],
      gradeformdata: {},
      rules: {
        mobile: [
          {validator: checkPhone, trigger: 'blur'}
        ]
      },
      projectarr:[],
      projectdefaultarr:[],
      weekarr:[],
      weekdefaultarr:[],
      multipleSelection:[],
      isshowdialog: false,
      uploadheader: {Authorization: this.$store.getters.getToken},
      isbusy: false,
      uploadurl: process.env.VUE_APP_API_ROOT+'/system/upload/images?storage=masseur',
      myConfig: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 240,
        // 初始容器宽度
        initialFrameWidth: '100%',
      }
    }
  },
  methods: {
    changethisinput(event,dv,item){
      let newprojectarr = [];
      let ids = "";
      this.projectarr.forEach((items,idks)=>{
        let uItems = this.cloneData(items);
        if( uItems.id == dv.id ){
          ids = idks;
          console.log(ids);
          uItems.tprice.forEach((km)=>{
            if( km.id == item.id ){
              km.tprice = event;
            }
          })
        }
        newprojectarr.push(uItems);
      });
      this.projectarr = newprojectarr;
      this.$nextTick(function() {
        this.projectarr.forEach((uk,ids)=>{
          this.editprojectcc.forEach((k)=>{
            if( uk.id == k.pid ){
              this.projectarr[ids].price = k.price;
              this.$refs.multipleTable.toggleRowSelection(this.projectarr[ids],true);
            }
          })
        })
      });
    },
    async getgrade() {
      let res = await this.httpget('/system/publicapi/getgrade');
      if (res.code === 0) {
        this.grade = res.data;
      }
    },
    SizeChange(val) {
      this.searchinfo.pagesize = val;
      this.getlist(1);
    },
    async editgrade(item) {
      let that = this;
      let res = await this.httpget('/system/masseur/getinfo', {id:item.id});
      if( res.code == 0 ){
        this.gradeformdata = res.data.info;
        if( res.data.weeks ){
          this.weekarr = res.data.weeks;
        } else {
          this.weekarr = this.weekdefaultarr;
        }
        let projectt = [];
        this.projectarr.forEach((item)=>{
          let uItem = this.cloneData(item);
          uItem.tprice = that.grade;
          projectt.push(uItem);
        });
        console.log(projectt);
        this.projectarr = projectt;
        this.editprojectcc = res.data.projects;
        if( res.data.projects ){
          this.$nextTick(function() {
            this.projectarr.forEach((uk,ids)=>{
              res.data.projects.forEach((k)=>{
                if( uk.id == k.pid ){
                  this.projectarr[ids].price = k.price;
                  this.projectarr[ids].tprice = k.tprice;
                  this.$refs.multipleTable.toggleRowSelection(this.projectarr[ids],true);
                }
              })
            })
          });
        }
        this.isshowdialog = true;
      } else {
        console.log("fail");
      }
    },
    addgrade(formName) {
      this.gradeformdata = {};
      this.gradeformdata = this.cloneData(this.defaultuserinfo);
      this.isshowdialog = true;
      if( this.weekarr ){
        let weeks = [];
        this.weekarr.forEach((item)=>{
          if( item.is_rest ) item.is_rest = 0;
          weeks.push(item);
        });
        this.weekarr = weeks;
      }
      if( this.projectarr ){
        let projectt = [];
        this.projectarr.forEach((item)=>{
          item.tprice = this.grade;
          projectt.push(item);
        });
        this.projectarr = projectt;
      }
      this.$nextTick(()=>{
        this.$refs[formName].resetFields();
      });
    },
    async projects(){
      let res = await this.httpget('/system/publicapi/getprojects');
      if (res.code === 0) {
        let projectArr = [];
        res.data.forEach((item)=>{
          if( !item.price ) item.price = 0.00;
          projectArr.push(item);
        });
        this.projectarr = projectArr;
        this.projectdefaultarr = projectArr;
      } else {
        console.log('fail');
      }
    },
    async weekend(){
      let res = await this.httpget('/system/publicapi/getweek');
      if (res.code === 0) {
        this.weekarr = res.data;
        this.weekdefaultarr = res.data;
      } else {
        console.log('fail');
      }
    },
    setRest(item){
      this.weekarr.forEach((res,index)=>{
        if( res.id == item.id ){
          res.is_rest = !res.is_rest;
        }
      });
    },
    async dosearch() {
      this.getlist(1);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async getlist(page) {
      this.searchinfo.page = page || this.searchinfo.page;
      this.isbusy = true;
      let res = await this.httpget('/system/masseur/index', this.searchinfo);
      if (res.code === 0) {
        this.list = [];
        res.data.data.forEach((data) => {
          this.list.push(data);
        })
        this.totalRows = res.data.total;
        this.isbusy = false;
      } else {
        this.isbusy = false;
      }

    },
    dosubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.suresubmit();
        } else {
          return false;
        }
      });
    },
    async suresubmit() {
      this.isbusy = true;
      this.gradeformdata.weeks = this.weekarr;
      this.gradeformdata.projects = this.multipleSelection;
      let res = await this.httppost('/system/masseur/save', this.gradeformdata);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        this.getlist(1);
        this.gradeformdata = {};
        this.isshowdialog = false;
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async setenable(item) {
      this.$confirm(this.$t('global.areyousure')).then(async () => {
        this.isbusy = true;
        let res = await this.httpput('/system/masseur/setenable', {id: item.id});
        if (res.code === 0) {
          this.$message({type: 'success', message: res.msg});
          let idx = this.list.findIndex((value) => {
            return value.id === item.id
          })
          if (idx !== -1) {
            this.list[idx].status = Math.abs(this.list[idx].status - 1);
          }
          this.isbusy = false;
        } else {
          this.isbusy = false;
          this.$message({type: 'error', message: res.msg});
        }
      }).catch(() => {
        this.isbusy = false;
        this.$message({type: 'info', message: 'Canceled'});
      });
    },
    async deluser(item) {
      this.$confirm(this.$t('global.areyousure')).then(async () => {
        this.isbusy = true;
        let res = await this.httpput('/system/masseur/delete', {id: item.id});
        if (res.code === 0) {
          this.$message({type: 'success', message: res.msg});
          this.getlist();
        } else {
          this.$message({type: 'error', message: res.msg});
        }
        this.isbusy = false;
      }).catch(() => {
        this.isbusy = false;
        this.$message({type: 'info', message: 'Canceled'});
      });
    },
    uploadsuccess(res){
      if(res.code===0){
        if(res.data[0]
            && res.data[0].indexOf(process.env.VUE_APP_IMG_DOMAIN)===-1
            && res.data[0].indexOf('http://')===-1){
          res.data[0] = process.env.VUE_APP_IMG_DOMAIN + '/storage/' + res.data[0];
        }
        this.gradeformdata.pic = res.data[0];
      }else{
        this.$message({type: 'error', message: res.msg});
      }
    },
    beforeupload(file) {
      const isJPG = (file.type === 'image/jpeg'||file.type === 'image/png');
      const isLt2M = file.size / 1024 / 1024 < 8;
      if (!isJPG) {
        this.$message.error('Upload avatar pictures can only be in JPG or PNG format!');
      }
      if (!isLt2M) {
        this.$message.error('Upload avatar image size can\'t exceed 2MB!');
      }
      return isJPG && isLt2M;
    },
  },
  mounted() {
    this.getlist(1);
    this.projects();
    this.weekend();
    this.getgrade();
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

.avatar {
  width: 100px;
  height: 100px;
  display: block;
}

.edui-popup-content.edui-default{ height: auto !important; }
.edui-combox-body.edui-default{
  height:25px;

}
.edui-default .edui-toolbar .edui-combox-body .edui-button-body{
  line-height: 25px;
}
.edui-default .edui-toolbar .edui-combox-body .edui-button-body{
  line-height: 25px !important;
}
.edui-default .edui-toolbar .edui-splitborder{
  height:25px !important;
}
.edui-default .edui-toolbar .edui-combox-body .edui-arrow{
  height:25px !important;
  width:13px !important;
}
#edui_fixedlayer{
  z-index:100000 !important;
}
.el-tab-pane{
  margin-top:20px;
}
.p-info{
  margin-left:20px; display: inline-block; width:50%; overflow: hidden; height: 30px; line-height: 30px; vertical-align: middle;
}
.week{
  width:100%;
}
.week ul{
  padding:0px;
  margin:0px;
}
.week ul li{
  display:inline-block;
  width:80px;
  text-align: center;
}
.week ul li span{
  display:inline-block;
  width:100%;
}
.week ul li span:first-child{
  background:#ecf5ff;
  height: 40px;
  line-height: 40px;
  border-right:1px solid #ffffff;
  border-bottom:1px solid #ffffff;
}
.week ul li span:last-child{
  background:#c1c1c1;
  height: 80px;
  line-height: 80px;
  border-right:1px solid #ffffff;
  color:#999;
  font-size:16px;
}
.week ul li span.rest{
  background:green;
  color:#ffffff;
}
.i-addd{
  padding:8px;
  background:#409EFF;
  color:#ffffff;
  border-radius:5px;
  margin-left:10px;
  cursor: pointer;
}
</style>
